<template>
  <div class="Extension_wrapper">
    <el-tabs type="card" v-model="activeName" @tab-click="getPosterDetail">
      <el-tab-pane label="推广海报" name="promotion">
        <el-alert title="设置自定义海报后，自动同步到 [推广海报] 页面" type="warning" :closable="false"></el-alert>
      </el-tab-pane>
      <el-tab-pane label="邀请导购员" name="kpi_user">
        <el-alert title="设置自定义海报后，自动同步到 [邀请导购员] 页面" type="warning" :closable="false"></el-alert>
      </el-tab-pane>
      <el-tab-pane label="直播邀请" name="live">
        <el-alert title="设置自定义海报后，自动同步到 [直播邀请] 页面" type="warning" :closable="false"></el-alert>
      </el-tab-pane>
    </el-tabs>
    <el-form :model="baseInfo" class="mt20 ml20">
      <p class="view_title mt10" v-if="activeName=='promotion'">导购员推广海报</p>
      <p class="view_title mt10" v-if="activeName=='kpi_user'">邀请导购员海报</p>
      <p class="view_title mt10" v-if="activeName=='live'">直播邀请海报</p>
      <div class="disflex flexdr mt10">
        <el-button type="primary" icon="el-icon-plus" @click="getFiles">添加自定义海报</el-button>
        <input type="file" ref="BgUrl" hidden accept="image/*" id="BgUrl" @change="uploadImage" />
        <div class="disflex flexdc ml10 cae">
          <p class>
            <span>建议尺寸750x1334px或9：16， JPG、PNG格式，图片小于2M</span>
          </p>
          <p class="mt10">
            <span>最多可设置1张自定义海报图</span>
          </p>
        </div>
      </div>

      <el-form-item prop="poster" class="up_poster mt20">
        <img
          v-show="!editPosterForm.logo"
          class="mt15 showImg"
          src="https://j.weizan.cn/youxiang/defult_poster_bg.png"
          alt
        />
        <div class="mt15 showImg" v-show="editPosterForm.logo">
          <img
            style="width: 210px;height: 50px;"
            class="title_bg"
            src="https://j.weizan.cn/youxiang/defult_title_bg.png"
          />
          <img
            :style="{ backgroundImage: 'url('+editPosterForm.logo+')'}"
            class="w100p h100p"
            :src="editPosterForm.logo"
          />
          <i
            @click.stop="delLogo"
            class="el-icon-circle-close pa cfff cp p5"
            style="font-size: 22px;color: #fff;border-radius: 0 0 0 8px;background-color: rgba(0,0,0,0.3);right: 0;"
          ></i>
        </div>

        <label for="brand-logo" class="el-upload el-upload--picture-card" style="display:none">
          <input
            type="file"
            ref="brandlogo"
            hidden
            id="brand-logo"
            data-type="brandLogo"
            name="upload"
            accept="image/*"
            @change="uploadImage"
          />
          <img
            v-if="editPosterForm.logo"
            :src="editPosterForm.logo"
            style="width:142px;height:255px;"
          />
        </label>
      </el-form-item>
    </el-form>
    <!-- 主页面 -->
  </div>
</template>

<script>
import GuideAjax from "@/utils/https/modules/Guide.request.js";
import VzanUpload from "@/utils/https/VzanUpload.js";
export default {
  name: "Extension", //
  components: {},
  props: {
    activityID: String,
  },
  data() {
    return {
      nodata: "",
      nobool: false,
      baseInfo: {
        id: "",
        actName: "", // 活动名称
        timeRange: [],
        actStartTime: "", // 活动开始时间
        actEndTime: "", // 活动结束时间
        // 活动区域列表
        areaList: [
          {
            city: "",
            county: "",
            province: "",
          },
        ],
        poster: "", // 活动海报图url
        complainMobile: "", // 投诉电话
        liveStartTime: "", // 直播开始时间
        liveUrl: "", // 直播地址
      },
      activeName: "promotion",
      editPosterForm: {
        actId: this.activityID,
        description: "",
        logo: "",
        sort: "",
        status: "active",
        type: "",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 删除海报
    delLogo() {
      this.editPosterForm.logo = "";
      this.submitInfo();
    },
    //点击上传
    getFiles() {
      if (this.editPosterForm.logo) {
        this.$message.error("最多可支持添加1张海报,请先删除！");
      } else {
        this.$refs.brandlogo.click();
      }
    },
    // 查询推广海报详情
    async getPosterDetail() {
      try {
        this.editPosterForm = {
          actId: this.activityID,
          description: "",
          logo: "",
          sort: "",
          status: "active",
          type: "",
        };
        const data = await GuideAjax.getPosterDetail({
          actId: this.activityID,
          type: this.activeName,
        });
        console.log(data.data);
        if (data.data) {
          this.editPosterForm = data.data;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 上传
    async uploadImage(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 2,
        });
        this.editPosterForm.logo = src;
        await this.submitInfo();
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // 确认保存或编辑海报
    async submitInfo() {
      let act = this.editPosterForm;
      act.type = this.activeName;

      console.log(act);
      await GuideAjax.SaveOrUpdatePosterDetail(act);
      this.$message.success("保存成功");
      await this.getPosterDetail();
    },
  },
  created() {
    this.getPosterDetail();
  },
  mounted() {
    this.$nextTick(() => {
      // 初始化 上传
      this.upload = new VzanUpload({
        actId: 0,
        category: "common",
        extension: "jpg",
        fileType: "image",
      });
    });
  },
};
</script>

<style lang="scss" >
.Extension_wrapper {
  .showImg {
    width: 225px;
    height: 406px;
    margin: 20px 5px 5px 0;
    position: relative;
    .w100p {
      width: 100% !important;
    }
    .h100p {
      height: 100% !important;
    }
    .title_bg{
      position: absolute;
      bottom: 20px;
      left: 5px;
    }
  }
  .showImg img:first-child {
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .view_title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 30px;
    &:before {
      display: inline-block;
      width: 3px;
      height: 23px;
      vertical-align: sub;
      background-color: #419eff;
      line-height: 2;
      margin-right: 20px;
      content: "";
    }
  }
  .select_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .secondsKill_table {
    margin-top: 20px;
    .el-table__body {
      .cell {
        span {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .tab-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }
  .up_poster {
    .el-form-item__content {
      display: flex;
      align-items: center;
      .el-upload--text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 178px;
        height: 178px;
      }
    }
  }
  .el-upload--picture-card {
    width: 148px;
    height: 260px;
  }
}
</style>
