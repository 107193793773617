var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Extension_wrapper" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.getPosterDetail },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "推广海报", name: "promotion" } },
            [
              _c("el-alert", {
                attrs: {
                  title: "设置自定义海报后，自动同步到 [推广海报] 页面",
                  type: "warning",
                  closable: false
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "邀请导购员", name: "kpi_user" } },
            [
              _c("el-alert", {
                attrs: {
                  title: "设置自定义海报后，自动同步到 [邀请导购员] 页面",
                  type: "warning",
                  closable: false
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "直播邀请", name: "live" } },
            [
              _c("el-alert", {
                attrs: {
                  title: "设置自定义海报后，自动同步到 [直播邀请] 页面",
                  type: "warning",
                  closable: false
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form",
        { staticClass: "mt20 ml20", attrs: { model: _vm.baseInfo } },
        [
          _vm.activeName == "promotion"
            ? _c("p", { staticClass: "view_title mt10" }, [
                _vm._v("导购员推广海报")
              ])
            : _vm._e(),
          _vm.activeName == "kpi_user"
            ? _c("p", { staticClass: "view_title mt10" }, [
                _vm._v("邀请导购员海报")
              ])
            : _vm._e(),
          _vm.activeName == "live"
            ? _c("p", { staticClass: "view_title mt10" }, [
                _vm._v("直播邀请海报")
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "disflex flexdr mt10" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.getFiles }
                },
                [_vm._v("添加自定义海报")]
              ),
              _c("input", {
                ref: "BgUrl",
                attrs: {
                  type: "file",
                  hidden: "",
                  accept: "image/*",
                  id: "BgUrl"
                },
                on: { change: _vm.uploadImage }
              }),
              _c("div", { staticClass: "disflex flexdc ml10 cae" }, [
                _c("p", {}, [
                  _c("span", [
                    _vm._v(
                      "建议尺寸750x1334px或9：16， JPG、PNG格式，图片小于2M"
                    )
                  ])
                ]),
                _c("p", { staticClass: "mt10" }, [
                  _c("span", [_vm._v("最多可设置1张自定义海报图")])
                ])
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "up_poster mt20", attrs: { prop: "poster" } },
            [
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.editPosterForm.logo,
                    expression: "!editPosterForm.logo"
                  }
                ],
                staticClass: "mt15 showImg",
                attrs: {
                  src: "https://j.weizan.cn/youxiang/defult_poster_bg.png",
                  alt: ""
                }
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editPosterForm.logo,
                      expression: "editPosterForm.logo"
                    }
                  ],
                  staticClass: "mt15 showImg"
                },
                [
                  _c("img", {
                    staticClass: "title_bg",
                    staticStyle: { width: "210px", height: "50px" },
                    attrs: {
                      src: "https://j.weizan.cn/youxiang/defult_title_bg.png"
                    }
                  }),
                  _c("img", {
                    staticClass: "w100p h100p",
                    style: {
                      backgroundImage: "url(" + _vm.editPosterForm.logo + ")"
                    },
                    attrs: { src: _vm.editPosterForm.logo }
                  }),
                  _c("i", {
                    staticClass: "el-icon-circle-close pa cfff cp p5",
                    staticStyle: {
                      "font-size": "22px",
                      color: "#fff",
                      "border-radius": "0 0 0 8px",
                      "background-color": "rgba(0,0,0,0.3)",
                      right: "0"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.delLogo($event)
                      }
                    }
                  })
                ]
              ),
              _c(
                "label",
                {
                  staticClass: "el-upload el-upload--picture-card",
                  staticStyle: { display: "none" },
                  attrs: { for: "brand-logo" }
                },
                [
                  _c("input", {
                    ref: "brandlogo",
                    attrs: {
                      type: "file",
                      hidden: "",
                      id: "brand-logo",
                      "data-type": "brandLogo",
                      name: "upload",
                      accept: "image/*"
                    },
                    on: { change: _vm.uploadImage }
                  }),
                  _vm.editPosterForm.logo
                    ? _c("img", {
                        staticStyle: { width: "142px", height: "255px" },
                        attrs: { src: _vm.editPosterForm.logo }
                      })
                    : _vm._e()
                ]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }